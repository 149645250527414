import $ = require("jquery");

import "./PasswordStrength.scss";

interface LsPasswordStrengthLevel {
    readonly Value: string;
    readonly CssClass: string;
}

class LsPasswordStrengthLevels {
    public static readonly Invalid: LsPasswordStrengthLevel = {
        Value: "Minimum 6 characters",
        CssClass: "lsc-password-strength-invalid",
    };
    public static readonly Weak: LsPasswordStrengthLevel = {
        Value: "Not the Best",
        CssClass: "lsc-password-strength-weak",
    };
    public static readonly Medium: LsPasswordStrengthLevel = {
        Value: "Average",
        CssClass: "lsc-password-strength-medium",
    };
    public static readonly Strong: LsPasswordStrengthLevel = {
        Value: "Fantastic!",
        CssClass: "lsc-password-strength-strong",
    };
}

export class LsPasswordStrength {
    protected static initialized = false;
    protected charTypes: Array<RegExp> = [
        /[0-9]/,
        /[^A-Za-z0-9]/,
        /[a-z]/,
        /[A-Z]/
    ];

    public constructor() {
        if (!LsPasswordStrength.initialized) {
            $(document).on("input blur", "input[data-password-strength]", this.setStrength);

            LsPasswordStrength.initialized = true;
        }
    }

    protected setStrength = (e: LsJQueryEvent) => {
        const $input = $(e.currentTarget);
        const selector: string = $input.data("passwordStrength");
        const $strengthWrapper = $(selector);
        const $strengthValue = $strengthWrapper.find("[data-password-strength-value]");

        const prevLevel: LsPasswordStrengthLevel = $strengthWrapper.data("prevLevel");
        const password = $input.val() as string || "";
        const strength = this.getStrength(password);
        const level = this.getLevel(password, strength);

        if ((prevLevel || password) && (level !== prevLevel)) {
            $strengthWrapper.addClass(level.CssClass);
            if (prevLevel) {
                $strengthWrapper.removeClass(prevLevel.CssClass);
            }

            $strengthValue.text(level.Value);

            $strengthWrapper.data("prevLevel", level);
        }
    }

    protected getStrength = (password: string): number => {
        // +1 for every 8 characters in length
        let strength = Math.floor(password.length / 8);

        for (let test of this.charTypes) {
            if (test.test(password)) {
                // +1 for each character type
                strength++;
            }
        }

        return strength;
    }

    protected getLevel = (password: string, strength: number) => {
        if (password.length < 6) {
            return LsPasswordStrengthLevels.Invalid;
        }
        if (strength >= 4) {
            return LsPasswordStrengthLevels.Strong;
        }
        if (strength === 3) {
            return LsPasswordStrengthLevels.Medium;
        }
        return LsPasswordStrengthLevels.Weak;
    }
}

export default LsPasswordStrength;