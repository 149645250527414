import $ = require("jquery");
import _debounce = require("lodash/debounce");

import { LsApi, ILsApiAjaxSettings } from "~/Src/Components/Api/Api";
import LsEvents from "~/Src/Components/Events/Events";

import "./AutoComplete.scss";

interface LsAutoCompleteRequests {
    [url: string]: {
        xhr?: JQuery.jqXHR;
        term: string;
    };
}

class LsAutoCompleteOptions {
    public static delay = 300;
    public static minLength = 3;
    public static formSelector = '[data-autocomplete-form]';
    public static fieldSelector = '[data-action="autocomplete"]';
    public static panelSelector = "[data-autocomplete-panel]";
    public static panelItemSelector = `[data-autocomplete-panel] a, [data-autocomplete-panel] button`;
}

//ts-keycode-enum
enum Key {
    Tab = 9,
    Enter = 13,
    Escape = 27,
    Space = 32,
    LeftArrow = 37,
    UpArrow = 38,
    RightArrow = 39,
    DownArrow = 40,
}

export class LsAutoComplete {
    protected static _window: Window;
    protected static get window() {
        return LsAutoComplete._window || (LsAutoComplete._window = window);
    }

    protected static initialized = false;
    protected static document = document;
    protected static $document = $(LsAutoComplete.document);

    protected static requests: LsAutoCompleteRequests = { };

    public constructor() {
        if (!LsAutoComplete.initialized) {
            //LsAutoComplete.$document.on("focusin", LsAutoCompleteOptions.formSelector, this.onFormFocusIn);
            LsAutoComplete.$document.on("focusout", LsAutoCompleteOptions.formSelector, this.onFormFocusOut);
            LsAutoComplete.$document.on("keydown", LsAutoCompleteOptions.fieldSelector, this.onFieldKeyDown);
            LsAutoComplete.$document.on("input", LsAutoCompleteOptions.fieldSelector, this.onFieldChange);
            LsAutoComplete.$document.on("mousedown", LsAutoCompleteOptions.panelSelector, this.onPanelMouseDown);
            LsAutoComplete.$document.on("keydown", LsAutoCompleteOptions.panelItemSelector, this.onResultKeyDown);

            if ($(LsAutoComplete.document.activeElement).is(LsAutoCompleteOptions.fieldSelector)) {
                this.showResults(LsAutoComplete.document.activeElement);
            }

            LsAutoComplete.initialized = true;
        }
    }

    // could clear focusout timeout instead of waiting 25ms
    // would need to store a timeout id for the specific form element
    //protected onFormFocusIn = (e: LsJQueryEvent) => { }

    protected onFormFocusOut = (e: LsJQueryEvent) => {
        LsAutoComplete.window.setTimeout(() => {
            if (typeof (LsAutoComplete.window as any).cvox?.Api?.getCurrentNode === "function") {
                (LsAutoComplete.window as any).cvox.Api.getCurrentNode((node: Element) => {
                    if (!e.currentTarget.contains(node)) { // the current node being voiced is in the form
                        const $field = $(e.currentTarget).find(LsAutoCompleteOptions.fieldSelector);
                        this.hideResults($field, false);
                    }
                });
            } else {
                if (!e.currentTarget.contains(LsAutoComplete.document.activeElement)) {
                    const $field = $(e.currentTarget).find(LsAutoCompleteOptions.fieldSelector);
                    this.hideResults($field, false);
                }
            }
        }, 120); // wait for focus to move to new element
    }

    protected onFieldKeyDown = (e: LsJQueryEvent) => {
        switch (e.which) {
            case Key.Escape:
            case Key.UpArrow:
                e.preventDefault();
                this.hideResults(e.currentTarget);
                break;
            case Key.DownArrow:
                e.preventDefault();
                this.showResultsDebouncedLeading(e.currentTarget, true);
                break;
        }
    }

    protected onFieldChange = (e: LsJQueryEvent) => {
        this.showResultsDebouncedTrailing(e.currentTarget);
    }

    protected onPanelMouseDown = (e: LsJQueryEvent) => {
        const $closestResult = $(e.target).closest(LsAutoCompleteOptions.panelItemSelector);
        if ($closestResult.length === 0) {
            e.preventDefault();
        }
    }

    protected onResultKeyDown = (e: LsJQueryEvent) => {
        switch (e.which) {
            case Key.Escape:
                e.preventDefault();
                const $form = $(e.currentTarget).closest("form");
                const $field = $form.find(LsAutoCompleteOptions.fieldSelector);
                this.hideResults($field);
                break;
            case Key.UpArrow:
            case Key.DownArrow:
                e.preventDefault();
                this.goToNeighbor(e.currentTarget, e.which === Key.DownArrow);
                break;
        }
    }

    protected showResults = (field: LsJQuerySelector, setPanelItemFocus = false) => {
        const $field = $(field);
        if ($field.length === 0) {
            return;
        }

        const $panel = this.getPanel(field);
        if (!$panel || ($panel.length === 0)) {
            return;
        }

        const term = ($field.val() as string).trim().toLowerCase();
        if (term.length >= LsAutoCompleteOptions.minLength) {
            const url = $field.data("url");
            if (term !== LsAutoComplete.requests[url]?.term) {
                LsAutoComplete.requests[url]?.xhr?.abort();
            }
            const resultsTerm = $panel.data("term")?.trim()?.toLowerCase();
            if (resultsTerm && (term === resultsTerm)) {
                $field.attr("aria-expanded", "true");
                $panel.prop("hidden", false);
                $panel.attr("aria-expanded", "true");
                if (setPanelItemFocus) {
                    const $result = $(LsAutoCompleteOptions.panelItemSelector);
                    if ($result.length > 0) {
                        const $first = $result.first();
                        $first.trigger("focus");
                    }
                }
            } else if (term !== LsAutoComplete.requests[url]?.term) {
                const $form = $field.closest("form");
                if ($form.length > 0) {
                    this.getResults($field, url, term, $form.serialize());
                }
            }
        } else {
            this.hideResults($field);
        }
    }

    protected showResultsDebouncedLeading = _debounce(this.showResults, LsAutoCompleteOptions.delay, { leading: true, trailing: false });

    protected showResultsDebouncedTrailing = _debounce(this.showResults, LsAutoCompleteOptions.delay);

    protected hideResults = (field: LsJQuerySelector, setFieldFocus = true) => {
        const $field = $(field);
        if ($field.length === 0) {
            return;
        }

        const $panel = this.getPanel($field);
        if (!$panel || ($panel.length === 0)) {
            return;
        }

        $field.attr("aria-expanded", "false");
        $panel.prop("hidden", true);
        $panel.attr("aria-expanded", "false");
        if (setFieldFocus) {
            $field.trigger("focus");
        }
    }

    protected getResults = (field: LsJQuerySelector, url: string, term: string, data: JQuery.PlainObject | string) => {
        const settings: ILsApiAjaxSettings = {
            method: "GET",
            url,
            global: false,
            data,
            success: (result, status, xhr) => {
                LsApi.onSuccess(result, status, xhr);

                // if no results, result.html should not be present
                if (!result.html) {
                    this.hideResults(field);
                } else {
                    const $field = $(field);
                    if ($field.length > 0) {
                        $field.attr("aria-expanded", "true");
                    }
                }

                delete LsAutoComplete.requests[url].xhr;
            },
            error: () => {
                delete LsAutoComplete.requests[url];
            },
        };
        LsAutoComplete.requests[url] = {
            xhr: LsApi.ajax(settings),
            term,
        };
    }

    protected goToNeighbor = (item: LsJQuerySelector, forward: boolean) => {
        const $item = $(item);
        if ($item.length === 0) {
            return;
        }

        const $form = $item.closest(LsAutoCompleteOptions.formSelector);
        if ($form.length === 0) {
            return;
        }

        const $items = $form.find(LsAutoCompleteOptions.panelItemSelector);
        if ($items.length === 0) {
            return;
        }

        const index = $items.index($item);
        if ((!forward && (index > 0)) || (forward && (index < $items.length - 1))) {
            const $neighbor = $items.eq(index + (forward ? 1 : -1));
            $neighbor.trigger("focus");
        } else if (!forward) {
            const $field = $form.find(LsAutoCompleteOptions.fieldSelector);
            if ($field.length > 0) {
                $field.trigger("focus");
            }
        }
    }

    protected getPanel = (field: LsJQuerySelector) => {
        const $field = $(field);
        if ($field.length === 0) {
            return;
        }

        const target = $field.data("target");
        if (!target) {
            return;
        }

        const $target = $(target);
        if ($target.length === 0) {
            return;
        }

        return $target;
    }
}

export default LsAutoComplete;