import $ = require("jquery");

import { ILsApiAjaxSettings } from "./Api";
import LsLogger from "~/Src/Components/Logging/Logger";
import LsModals from "~/Src/Components/Modal/Modal";
import { generateUUID } from "~/Src/Components/Utilities/Utilities";

export class LsApiUtilities {
    public static addAntiForgeryToken(settings: ILsApiAjaxSettings) {
        const data = {};
        const $token = $("#lsLayoutAntiForgeryToken input");
        if ($token.length > 0) {
            data[$token.attr("name")] = $token.val();
            LsApiUtilities.addDataToSettings(settings, data);
        }
    }

    public static addTraceLogs(settings: ILsApiAjaxSettings) {
        const traceLogsData = LsLogger.getTraceData();
        if (Object.keys(traceLogsData).length > 0) {
            LsApiUtilities.addDataToSettings(settings, traceLogsData);
        }
    }

    public static addRequestedFromModal(settings: ILsApiAjaxSettings, xhr?: JQuery.jqXHR) {
        let value = "ls-modal";
        if (("headers" in settings) && ("X-Requested-From" in settings.headers)) {
            value = `${settings.headers["X-Requested-From"]}, ${value}`;
        }
        const headers = { "X-Requested-From": value };
        if (xhr) {
            LsApiUtilities.addHeadersToXhr(xhr, headers);
        } else {
            LsApiUtilities.addHeadersToSettings(settings, headers);
        }
    }

    public static addRequestId(settings: ILsApiAjaxSettings) {
        const requestId = LsApiUtilities.getRequestId(settings);
        if (!requestId) {
            const headers = { "X-Request-ID": generateUUID() };
            LsApiUtilities.addHeadersToSettings(settings, headers);
        }
    }

    public static getRequestId(settings: ILsApiAjaxSettings) {
        return LsApiUtilities.getHeaderFromSettings(settings, "X-Request-ID");
    }

    public static addViewportSize(settings: ILsApiAjaxSettings) {
        const viewportWidth = LsApiUtilities.getHeaderFromSettings(settings, "X-Viewport-Width");
        const viewportHeight = LsApiUtilities.getHeaderFromSettings(settings, "X-Viewport-Height");
        if ((viewportWidth === undefined) || (viewportHeight === undefined)) {
            const headers = {
                "X-Viewport-Width": window.innerWidth,
                "X-Viewport-Height": window.innerHeight,
            };
            LsApiUtilities.addHeadersToSettings(settings, headers);
        }
    }

    public static addUtcOffset(settings: ILsApiAjaxSettings) {
        const utcOffset = LsApiUtilities.getHeaderFromSettings(settings, "X-UTC-Offset-Minutes");
        if (!utcOffset) {
            const headers = { "X-UTC-Offset-Minutes": (new Date()).getTimezoneOffset() * -1 };
            LsApiUtilities.addHeadersToSettings(settings, headers);
        }
    }

    public static addAjaxUnobtrusiveContext(settings: ILsApiAjaxSettings) {
        const $forms = $('form[data-ajax="true"]');
        for (const form of $forms.toArray() as Array<HTMLFormElement>) {
            const $form = $(form);
            const data = $form.data();
            const url = data.ajaxUrl || form.action;
            if (url === settings.url) {
                settings.context = $form.get(0);
                break;
            }
        }
    }

    public static isPost(settings: ILsApiAjaxSettings) {
        if (!("LsIsPost" in settings)) {
            settings.LsIsPost = (("method" in settings) || ("type" in settings)) && ((settings.method || settings.type).toUpperCase() === "POST");
        }
        return settings.LsIsPost;
    }

    public static isAjaxUnobtrusiveRequest(settings: ILsApiAjaxSettings) {
        if (!("LsIsAjaxUnobtrusiveRequest" in settings)) {
            const requestedWith = LsApiUtilities.getDataFromSettings(settings, "X-Requested-With");
            settings.LsIsAjaxUnobtrusiveRequest = requestedWith === "XMLHttpRequest";
        }
        return settings.LsIsAjaxUnobtrusiveRequest;
    }

    public static isRequestedFromModal(settings: ILsApiAjaxSettings) {
        if (!("LsIsRequestedFromModal" in settings)) {
            let requestedFrom = LsApiUtilities.getDataFromSettings(settings, "X-Requested-From");
            if (!requestedFrom) {
                if (LsModals.isInModal(settings.context)) {
                    requestedFrom = "ls-modal";
                }
            }
            settings.LsIsRequestedFromModal = (typeof requestedFrom === "string") && (requestedFrom.indexOf("ls-modal") >= 0);
        }
        return settings.LsIsRequestedFromModal;
    }

    public static addDataToSettings(settings: ILsApiAjaxSettings, data: { [key: string]: any }) {
        if (settings.data) {
            if (!("contentType" in settings) || settings.contentType === "application/x-www-form-urlencoded" || settings.contentType === "multipart/form-data") {
                if (typeof settings.data === "string") {
                    const params = $.param(data);
                    if (params.length > 0) {
                        settings.data = `${settings.data}&${params}`;
                    }
                } else if (Array.isArray(settings.data)) {
                    const d = { ...data };
                    for (const { name } of settings.data) {
                        if (name in d) {
                            settings.data[name] = d[name];
                            delete d[name];
                        }
                    }
                    for (const k of Object.keys(d)) {
                        (settings.data as Array<any>).push({ name: k, value: d[k] });
                    }
                } else {
                    settings.data = { ...settings.data, ...data };
                }
            } else if (settings.data instanceof FormData) {
                for (const k of Object.keys(data)) {
                    settings.data.append(k, data[k]);
                }
            }
        } else {
            settings.data = $.param(data);
        }
    }

    public static getDataFromSettings(settings: ILsApiAjaxSettings, key: string) {
        if (settings.data) {
            if (typeof settings.data === "string") {
                const d = decodeURIComponent(settings.data);
                if (d.indexOf(key) > -1) {
                    const pairs = settings.data.split("&");
                    for (const pair of pairs) {
                        let [k, v] = pair.split("=");
                        k = decodeURIComponent(k);
                        if (k === key) {
                            return decodeURIComponent(v);
                        }
                    }
                }
            } else if (Array.isArray(settings.data)) {
                for (const { name, value } of settings.data) {
                    if (name === key) {
                        return value;
                    }
                }
            } else if (key in settings.data) {
                return settings.data[key];
            }
        }
        return undefined;
    }

    public static addHeadersToSettings(settings: ILsApiAjaxSettings, headers: { [key: string]: any }) {
        if (!("headers" in settings)) {
            settings.headers = {};
        }
        settings.headers = { ...settings.headers, ...headers };
    }

    public static getHeaderFromSettings(settings: ILsApiAjaxSettings, key: string) {
        if (settings.headers && key in settings.headers) {
            return settings.headers[key];
        }
        return undefined;
    }

    public static addHeadersToXhr(xhr: JQuery.jqXHR, headers: { [key: string]: any }) {
        for (const k of Object.keys(headers)) {
            xhr.setRequestHeader(k, headers[k]);
        }
    }

    public static isXhr(xhr: any): xhr is JQuery.jqXHR {
        return "setRequestHeader" in xhr;
    }

    public static global(settings: ILsApiAjaxSettings) {
        return !!(settings && (!("global" in settings) || settings.global));
    }
}

export default LsApiUtilities;