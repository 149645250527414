import LsLogger from "../Logging/Logger";
import LsUdoManager from "../Utilities/UdoManager";
import LsScriptLoader from "~/Src/Components/ScriptLoader/ScriptLoader";

export class LsOrdergroove {
    public static _window: any;
    public static get window() {
        return LsOrdergroove._window || (LsOrdergroove._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsOrdergroove._document || (LsOrdergroove._document = LsOrdergroove.window.document);
    }

    protected static initialized = false;

    protected static GetDomain() {
        var prodDomain = "https://static.ordergroove.com";
        var stageDomain = "https://staging.static.ordergroove.com";

        var isProd = LsOrdergroove.window.location.href.indexOf("www.lovelyskin.com") > -1;

        return isProd ? prodDomain : stageDomain;
    }

    protected static MerchantId = "3749a396ca0311e983fcbc764e10b970";

    protected static OgInit() {
        if (("ordergroove" in LsOrdergroove.window && LsOrdergroove.window.OG_main_loaded)) {
            LsLogger.log(`Ordergroove - init: main.js already loaded`, "info");

            if (LsOrdergroove.window.og_settings !== null && LsOrdergroove.window.og_settings !== undefined) {
                LsLogger.log(`Ordergroove - settings: ${JSON.stringify(LsOrdergroove.window.og_settings)}`, "info");
            }

            return;
        }

        let domain = LsOrdergroove.GetDomain();
        let url = domain + "/" + LsOrdergroove.MerchantId + "/main.js";

        LsScriptLoader.load({ url: url, async: true, loadCallback: LsOrdergroove.OnLoad});
    }

    protected static OnLoad() {
        LsLogger.log(`Ordergroove - main.js loaded`, "info");

        LsOrdergroove.window.ordergroove = LsOrdergroove;
        
        LsOrdergroove.window.OG_main_loaded = true;

        LsOrdergroove.GetPageViewData();

        if (LsOrdergroove.window.OGQueue) {
            LsLogger.log(`Ordergroove - queue found`, "info");

            if (LsOrdergroove.window.OGQueue.length == 0) {
                LsLogger.log(`Ordergroove - no items in queue`, "info");
            } else {
                LsLogger.log(`Ordergroove - queued items: ${LsOrdergroove.window.OGQueue.length}`, "info");

                while (LsOrdergroove.window.OGQueue.length > 0) {
                    var fn = LsOrdergroove.window.OGQueue.shift();

                    if (fn) {
                        fn();
                    }
                }
            }
        }
    }

    protected static GetPageViewData() {
        let path = window.location.pathname;

        if (path.indexOf("/cart") !== -1) {
            LsOrdergroove.CartPageView()
        } else if (path.indexOf("/securecheckout/confirmation") !== -1) {
            LsOrdergroove.OrderConfirmationPageView();
        } else if (path.indexOf("/securecheckout") !== -1) {
            LsOrdergroove.CheckoutPageView();
        } 
    }

    public constructor() {
        if (!LsOrdergroove.initialized) {
            LsOrdergroove.OgInit();

            LsOrdergroove.initialized = true;
        }
    }

    protected static AddToQueue() {
        return function (fn) {
            if (!LsOrdergroove.window.OGQueue) {
                LsLogger.log(`Ordergroove - building queue`, "info");
                LsOrdergroove.window.OGQueue = [];
            }

            LsLogger.log(`Ordergroove - adding to queue`, "info");
            LsOrdergroove.window.OGQueue.push(fn);
        };
    }

    //Tags
    //Not setup...needs item properties and selectedItemIds in accounts/orders page
    /*protected static SetQuantity(data) {
        var payload = {
            id: data.externalId,
            module: data.subscription.module,
            quantity: data.quantity,
        };

        var fn = function () {
            LsLogger.log(`Ordergroove - set quantity: ${JSON.stringify(payload)}`, "info");
            LsOrdergroove.window.OG.setQuantity(payload);
        }

        if (LsOrdergroove.window.OG && LsOrdergroove.window.OG.setQuantity) {
            fn();
        }
        else {
            LsOrdergroove.AddToQueue()(fn);
        }
    }*/

    protected static UpdateQuantity(data) {
        var payload = [];
        
        for(var i = 0; i < data.items.length; i++) {
        var item = data.items[i];
        payload.push({
            id: item.selectedItemExternalId,
            quantity: item.currentQuantity,
            module: "sc",
        });          
        }

        var fn = function() {
            LsLogger.log(`Ordergroove - update cart: ${payload}`, "info");
            LsOrdergroove.window.OG.updateCart(payload);     
        };
      
        if (LsOrdergroove.window.OG && LsOrdergroove.window.OG.updateCart) {       
            fn();
        } else {
            LsOrdergroove.AddToQueue()(fn);  
        }
    }

    //items from account page do not have subscription property so this does not actually update on the OG side
    /*protected static AddToCart(data) {
        var payload = [];

        for (var i = 0; i < data.items.length; i++) {
            var item = data.items[i];
            if (item.subscription && item.subscription.module) {
                payload.push({
                    id: item.selectedItemExternalId,
                    quantity: item.addedQuantity,
                    module: item.subscription.module,
                });
            }
        }

        if (payload.length > 0) {
            var fn = function () {
                LsLogger.log(`Ordergroove - add to cart: ${JSON.stringify(payload)}`, "info");
                LsOrdergroove.window.OG.updateCart(payload);
            }

            if (LsOrdergroove.window.OG && LsOrdergroove.window.OG.updateCart) {
                fn();
            } else {
                LsOrdergroove.AddToQueue()(fn);
            }
        }
    }*/

    protected static RemoveFromCart(data) {
        var payload = [];

        for (var i = 0; i < data.items.length; i++) {
            var item = data.items[i];
            payload.push({
                id: item.selectedItemExternalId,
            });
        }

        var fn = function () {
            LsLogger.log(`Ordergroove - remove from cart: ${payload}`, "info");
            LsOrdergroove.window.OG.removeFromCart(payload, { isAjax: false });
        }

        if (LsOrdergroove.window.OG && LsOrdergroove.window.OG.removeFromCart) {
            fn();
        } else {
            LsOrdergroove.AddToQueue()(fn);
        }
    }

    protected static CartPageView() {
        let cart = LsUdoManager.get("cart");

  	    let products = [];

  	    let ogcart = {
            order_total: 0,
            products: undefined,
            subtotal: undefined,
            tax_total: undefined,
            shipping_total: undefined
        }; 

  	    if (cart && cart.lineItems) {
            for (let i = 0; i < cart.lineItems.length; i++) {
                let lineItem = cart.lineItems[i];
                products.push({
                  id: lineItem.externalId,
                  quantity: lineItem.quantity,
                  unit_price: lineItem.salePrice,
                  total_price: lineItem.totalPrice,        
                });
            }      
      
            ogcart.products = products;
            ogcart.subtotal = cart.subtotal;
            ogcart.tax_total = cart.taxAmount;
            ogcart.shipping_total = cart.shippingAmount;
        }	

        LsOrdergroove.window.og_settings = {
            page_type: "2",
            cart: ogcart,
        };
    
        LsOrdergroove.OgInit();
    }

    protected static CheckoutPageView() {
        let cart = LsUdoManager.get("cart");

        if (!cart || !cart.lineItems) {
            return;
        }

        let products = [];
        for (let i = 0; i < cart.lineItems.length; i++) {
            let lineItem = cart.lineItems[i];
            products.push({
                id: lineItem.externalId,
                quantity: lineItem.quantity,
                unit_price: lineItem.salePrice,
                total_price: lineItem.totalPrice,
            });
        }

        let ogCart = {
            products: products,
            subtotal: cart.subtotal,
            tax_total: cart.taxAmount,
            shipping_total: cart.shippingAmount,
            order_total: cart.grandTotal,
        };

        LsOrdergroove.window.og_settings = {
            page_type: "3",
            cart: ogCart
        };

        LsOrdergroove.OgInit();
    }

    protected static OrderConfirmationPageView() {
        LsOrdergroove.window.og_settings = {
            page_type: "4",
        };

        LsOrdergroove.OgInit();
    }
}

export default LsOrdergroove;