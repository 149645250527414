import $ = require("jquery");

import { ILsApiAjaxSettings } from "./Api";
import LsApiUtilities from "./ApiUtilities";
import { LsCallback, LsCallbackParameters } from "~/Src/Components/Callback/Callback";
import { LsEvents, ILsEventsData } from "~/Src/Components/Events/Events";
import LsLogger from "~/Src/Components/Logging/Logger";
import LsModals from "~/Src/Components/Modal/Modal";
import LsRecaptcha from "~/Src/Components/Recaptcha/Recaptcha";


export type ILsStandardJsonResponse = ILsStandardJsonResponse20;
interface ILsStandardJsonResponse20 {
    parsingVersion: "2.0";
    success?: boolean;
    actions?: Array<ILsActions>;
}


type ILsActions = ILsAddActions | ILsExecuteActions | ILsLoadActions | ILsOpenActions | ILsUpdateActions | ILsLegacyActions;


type ILsAddActions = ILsAddEventAction;
interface ILsAddAction {
    type: "add";
}
interface ILsAddEventAction extends ILsAddAction {
    mode: "event";
    value: ILsEventsData;
}


type ILsExecuteActions = ILsExecuteFunctionAction | ILsExecuteRedirectAction | ILsExecuteRefreshAction;
interface ILsExecuteAction {
    type: "execute";
}
interface ILsExecuteFunctionAction extends ILsExecuteAction {
    mode: "function";
    value: {
        path: string; // TODO
        name: string;
        parameters?: LsCallbackParameters;
    };
}
interface ILsExecuteRedirectAction extends ILsExecuteAction {
    mode: "redirect";
    value: string;
}
interface ILsExecuteRefreshAction extends ILsExecuteAction {
    mode: "refresh";
}


type ILsLoadActions = ILsLoadModalAction;
interface ILsLoadAction {
    type: "load";
}
interface ILsLoadModalAction extends ILsLoadAction {
    mode: "modal";
    value: string;
}


type ILsOpenActions = ILsOpenModalAction;
interface ILsOpenAction {
    type: "open";
}
interface ILsOpenModalAction extends ILsOpenAction {
    mode: "modal";
    value: string;
}


type ILsUpdateActions = ILsUpdateDomAction;
interface ILsUpdateAction {
    type: "update";
}
interface ILsUpdateDomAction extends ILsUpdateAction {
    mode: "dom";
    value: {
        method: "before" | "after" | "prepend" | "append" | "replace" | "remove" | "empty";
        target: string;
        html?: string;
    };
}


type ILsLegacyActions = ILsLegacyNotificationAction;
interface ILsLegacyAction {
    type: "legacy";
}
interface ILsLegacyNotificationAction extends ILsLegacyAction {
    mode: "notification";
    value: {
        type: "error" | "info" | "success" | "warning";
        preventClose?: boolean;
        html: string;
    };
}


class LsStandardJsonResponse {
    protected static _window: Window;
    protected static get window() {
        return LsStandardJsonResponse._window || (LsStandardJsonResponse._window = window);
    }

    protected _global: boolean;
    protected get global() {
        return this._global || (this._global = LsApiUtilities.global(this.settings));
    }

    protected settings: ILsApiAjaxSettings;
    protected static actions: { [type: string]: { [mode: string]: (action: ILsActions) => boolean; } } = {
        add: {
            event: LsStandardJsonResponse.addEvent,
        },
        execute: {
            function: LsStandardJsonResponse.executeFunction,
            redirect: LsStandardJsonResponse.executeRedirect,
            refresh: LsStandardJsonResponse.executeRefresh,
        },
        load: {
            modal: LsStandardJsonResponse.loadModal,
        },
        open: {
            modal: LsStandardJsonResponse.openModal,
        },
        update: {
            dom: LsStandardJsonResponse.updateDom,
        },
    };

    protected static updateDomActions: { [method: string]: ($target: JQuery, $html: JQuery) => void; } = {
        before: LsStandardJsonResponse.updateDomBefore,
        after: LsStandardJsonResponse.updateDomAfter,
        prepend: LsStandardJsonResponse.updateDomPrepend,
        append: LsStandardJsonResponse.updateDomAppend,
        replace: LsStandardJsonResponse.updateDomReplace,
    };

    protected static updateDomNoContentActions: { [method: string]: ($target: JQuery) => void; } = {
        remove: LsStandardJsonResponse.updateDomRemove,
        empty: LsStandardJsonResponse.updateDomEmpty,
    };

    public constructor(response: ILsStandardJsonResponse, settings?: ILsApiAjaxSettings) {
        this.settings = settings;

        if (Array.isArray(response.actions)) {
            for (const action of response.actions) {
                if (action.type in LsStandardJsonResponse.actions) {
                    if (action.mode in LsStandardJsonResponse.actions[action.type]) {
                        const success = LsStandardJsonResponse.actions[action.type][action.mode](action);
                        if (!success) {
                            this.showGenericError();
                        }
                    } else {
                        LsStandardJsonResponse.logError(`unknown action mode [type=${action.type}] [mode=${action.mode}]`);
                        this.showGenericError();
                    }
                } else {
                    LsStandardJsonResponse.logError(`unknown action type [type=${action.type}] [mode=${action.mode}]`);
                    this.showGenericError();
                }

                // actions that end execution
                switch (action.type) {
                    case "execute":
                        switch (action.mode) {
                            case "redirect":
                            case "refresh":
                                return;
                        }
                        break;
                }
            }
        }
    }

    protected static addEvent(action: ILsAddEventAction): boolean {
        if (action.value) {
            LsEvents.trigger(action.value);
            return true;
        } else {
            LsStandardJsonResponse.logError(`no redirect URL [${action.value}]`);
        }
        return false;
    }

    protected static executeFunction(action: ILsExecuteFunctionAction): boolean {
        if (action.value) {
            if (action.value.name) {
                // TODO - implement action.value.path
                if (action.value.name in LsStandardJsonResponse.window) {
                    LsCallback.call(action.value.name, action.value.parameters);
                    return true;
                } else {
                    LsStandardJsonResponse.logError(`function [${action.value.name}] not found`);
                }
            } else {
                LsStandardJsonResponse.logError(`no function name [${action.value.name}]`);
            }
        } else {
            LsStandardJsonResponse.logError(`no action value object [${action.value}]`);
        }
        return false;
    }

    protected static executeRedirect(action: ILsExecuteRedirectAction): boolean {
        if (action.value) {
            LsStandardJsonResponse.window.location.href = action.value;
            return true;
        } else {
            LsStandardJsonResponse.logError(`no redirect URL [${action.value}]`);
        }
        return false;
    }

    protected static executeRefresh(action: ILsExecuteRefreshAction): boolean {
        LsStandardJsonResponse.window.location.reload(true);
        return true;
    }

    protected static loadModal(action: ILsLoadModalAction): boolean {
        if (action.value) {
            LsModals.openModalByUrl(action.value);
            return true;
        } else {
            LsStandardJsonResponse.logError(`no modal URL [${action.value}]`);
        }
        return false;
    }

    protected static updateDom(action: ILsUpdateDomAction): boolean {
        if (action.value) {
            if (action.value.target) {
                const $target = $(action.value.target);
                if ($target.length > 0) {
                    if (action.value.method in LsStandardJsonResponse.updateDomNoContentActions) {
                        LsStandardJsonResponse.updateDomNoContentActions[action.value.method]($target);
                        return true;
                    } else if (action.value.method in LsStandardJsonResponse.updateDomActions) {
                        if (action.value.html) {
                            const $html = $(action.value.html);
                            LsStandardJsonResponse.updateDomActions[action.value.method]($target, $html);
                            const $forms = $html.find("form").addBack("form");
                            if ($forms.length > 0) {
                                for (const form of $forms.toArray()) {
                                    const $form = $(form) as JQuery<HTMLFormElement>;
                                    const validator: JQueryValidation.Validator = $form.data("validator");
                                    if (!validator) {
                                        $.validator.unobtrusive.parse($form);
                                    }
                                    const $recaptcha = $form.find("[data-recaptcha-options]");
                                    if ($recaptcha.length > 0) {
                                        LsRecaptcha.render($form);
                                    }
                                }
                            }
                            return true;
                        } else {
                            LsStandardJsonResponse.logError(`no HTML content [target=${action.value.target}] [method=${action.value.method}] [html=${action.value.html}]`);
                        }
                    } else {
                        LsStandardJsonResponse.logError(`unknown action method [target=${action.value.target}] [method=${action.value.method}] [html=${action.value.html}]`);
                    }
                } else {
                    LsStandardJsonResponse.logError(`element not found [target=${action.value.target}] [method=${action.value.method}] [html=${action.value.html}]`);
                }
            } else {
                LsStandardJsonResponse.logError(`no element selector [target=${action.value.target}] [method=${action.value.method}] [html=${action.value.html}]`);
            }
        } else {
            LsStandardJsonResponse.logError(`no action value object [${action.value}]`);
        }
        return false;
    }

    protected static updateDomRemove($target: JQuery) {
        $target.remove();
    }

    protected static updateDomEmpty($target: JQuery) {
        $target.empty();
    }

    protected static updateDomBefore($target: JQuery, $html: JQuery) {
        $target.before($html);
    }

    protected static updateDomAfter($target: JQuery, $html: JQuery) {
        $target.after($html);
    }

    protected static updateDomPrepend($target: JQuery, $html: JQuery) {
        $target.prepend($html);
    }

    protected static updateDomAppend($target: JQuery, $html: JQuery) {
        $target.append($html);
    }

    protected static updateDomReplace($target: JQuery, $html: JQuery) {
        $target.replaceWith($html);
    }

    protected static openModal(action: ILsOpenModalAction): boolean {
        if (action.value) {
            LsModals.openModalByTarget(action.value);
        } else {
            LsStandardJsonResponse.logError(`no modal selector [${action.value}]`);
        }
        return false;
    }

    protected static logError(message: string) {
        LsLogger.log(`LsStandardJsonResponse - ${message}`, "fatal");
    }

    protected showGenericError = () => {
        if (this.global) {
            LsModals.openErrorNotificationModal("An error has occurred.", "", { destroyOnClose: true });
        }
    }
}

export class LsStandardJsonResponseManager {
    public static isStandardJsonResponse(response: unknown): response is ILsStandardJsonResponse {
        return LsStandardJsonResponseManager.isObject(response) && (response.parsingVersion === "2.0");
    }

    public static run(response: ILsStandardJsonResponse, status: JQuery.Ajax.SuccessTextStatus, xhr: JQuery.jqXHR, settings?: ILsApiAjaxSettings) {
        const r = new LsStandardJsonResponse(response, settings);
        $(document).trigger("ajaxPageLoad");
    }

    protected static isObject(obj: unknown): obj is { [key: string]: any } {
        return !Array.isArray(obj) && (typeof obj === "object");
    }
}