import { ILsJsonResult } from "~/Src/Components/Api/JsonResult";
import LsApi from "~/Src/Components/Api/Api";
import LsModals from "~/Src/Components/Modal/Modal";

var mo = new LsModals();

export const setUpRemoveFromCartFunction = () => {
    (window as any).RemoveItemOnSuccess = (result: ILsJsonResult, status, xhr) => {
        const d = document;
        LsApi.onSuccess(result, status, xhr);

        if (result.success) {
            const qty = parseInt(result.data?.itemCount) || 0;
            const cartQuantities = Array.from(d.querySelectorAll("[data-cart-qty]"));
            for (const cartQuantity of cartQuantities) {
                cartQuantity.textContent = String(qty);
            }
            const cartQuantitySingulars = Array.from(d.querySelectorAll<HTMLElement>("[data-cart-qty-singular]"));
            for (const cartQuantitySingular of cartQuantitySingulars) {
                cartQuantitySingular.hidden = qty !== 1;
            }
            const cartQuantityPlurals = Array.from(d.querySelectorAll<HTMLElement>("[data-cart-qty-plural]"));
            for (const cartQuantityPlural of cartQuantityPlurals) {
                cartQuantityPlural.hidden = qty === 1;
            }
        } else {
            let disabledRemoveBtn = d.querySelector<HTMLElement>('.lsx-cartpanel-list button[data-disabled-by]');

            if (disabledRemoveBtn) {
                disabledRemoveBtn.removeAttribute("data-disabled-by");
                disabledRemoveBtn.removeAttribute("disabled");
            }

            LsModals.openWarningNotificationModal("", result.message);
        }
    };

    (window as any).UpdateCartOnSuccess = (result: ILsJsonResult, status, xhr) => {
        const d = document;
        LsApi.onSuccess(result, status, xhr);
        if (!result.success) {
            LsModals.openWarningNotificationModal("", result.message);
        }
    };

    (window as any).AddSampleOnSuccess = (result: ILsJsonResult, status, xhr) => {
        const d = document;
        LsApi.onSuccess(result, status, xhr);

        if (result.success) {

        } else {
            LsModals.openWarningNotificationModal("", result.message);
        }
    };
}