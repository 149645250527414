import $ = require("jquery");

import "./Recaptcha.scss";

export class LsRecaptcha {
    // ReSharper disable InconsistentNaming
    protected static _window: Window;
    protected static get window() {
        return LsRecaptcha._window || (LsRecaptcha._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsRecaptcha._document || (LsRecaptcha._document = document);
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;
    protected $form: JQuery;
    protected static recaptchaSelector = "[data-recaptcha-options]";

    public constructor() {
        if (!LsRecaptcha.initialized) {
            $(LsRecaptcha.document).on("click", "[data-recaptcha-submit]", e => {
                if (LsRecaptcha.window.grecaptcha && LsRecaptcha.window.grecaptcha.execute) {
                    this.$form = $(e.currentTarget).closest("form");
                    const $recaptcha = this.$form.find(LsRecaptcha.recaptchaSelector);
                    if ($recaptcha.length > 0) {
                        const id = $recaptcha.data("recaptchaId");
                        if (id !== undefined) {
                            e.preventDefault();

                            const isValid = this.$form.valid();
                            if (isValid) {
                                LsRecaptcha.window.grecaptcha.execute(id);
                            }
                        }
                    }
                }
            });

            LsRecaptcha.window.GoogleRecaptchaOnSubmit = token => {
                this.$form.submit();
            }

            const $recaptchas = $(LsRecaptcha.recaptchaSelector);
            for (const el of $recaptchas.toArray()) {
                LsRecaptcha.render(el);
            }

            LsRecaptcha.initialized = true;
        }
    }

    public static render = (el: LsJQuerySelector) => {
        if (LsRecaptcha.window.grecaptcha && LsRecaptcha.window.grecaptcha.render) {
            let $recaptcha = $(el);
            if ($recaptcha.length > 0) {
                if (!$recaptcha.is(LsRecaptcha.recaptchaSelector)) {
                    $recaptcha = $recaptcha.find(LsRecaptcha.recaptchaSelector);
                }
                if ($recaptcha.length > 0) {
                    const data = $recaptcha.data() as ReCaptchaV2.Parameters;
                    if (!("recaptchaId" in data)) {
                        if (data.size !== "invisible") {
                            const width = $(LsRecaptcha.window).width();
                            if (width < 360) {
                                $recaptcha.attr("data-size", "compact");
                                data.size = "compact";
                            }
                        }
                        const id = LsRecaptcha.window.grecaptcha.render($recaptcha.get(0), data);
                        $recaptcha.data("recaptchaId", id);
                    }
                }
            }
        }
    }

    //From Docs: Each reCAPTCHA user response token can only be verified once. If you need a new token, you will need to call grecaptcha.reset() to ask the end user to verify with reCAPTCHA again.
    public static reset() {
        if (LsRecaptcha.window.grecaptcha && LsRecaptcha.window.grecaptcha.reset) {
            LsRecaptcha.window.grecaptcha.reset();
        }
    }
}

export default LsRecaptcha;