import $ = require("jquery");
import _debounce = require("lodash/debounce");

import "./Filter.scss";

type LsFilterMatchTypes = "all" | "any" | "exact";

class LsFilterOptions {
    public static delay = 100;
    public static defaultMatchType: LsFilterMatchTypes = "all";
    public static fieldSelector = '[data-action="filter"]';
}

export class LsFilter {
    protected static document = document;

    protected static initialized = false;

    public constructor() {
        if (!LsFilter.initialized) {
            this.filter();

            $(() => {
                this.filter();
            });

            $(LsFilter.document).on("input", LsFilterOptions.fieldSelector, this.filterDebounced);

            LsFilter.initialized = true;
        }
    }

    protected filter = (e?: LsJQueryEvent<HTMLInputElement>) => {
        let inputs: Array<HTMLInputElement> = [];

        if (e?.currentTarget) {
            inputs.push(e.currentTarget);
        } else {
            inputs = Array.from(LsFilter.document.querySelectorAll(LsFilterOptions.fieldSelector));
        }

        for (const input of inputs) {
            const data = input.dataset;
            const matchType = (data.match as LsFilterMatchTypes) || LsFilterOptions.defaultMatchType;
            const term = input.value.trim();
            const terms = matchType === "exact" ? [term] : term.split(/\s+/);
            let counter = 0;

            const elements = Array.from(LsFilter.document.querySelectorAll<HTMLElement>(data.target));
            for (const el of elements.reverse()) {
                const matched = this.match(el, matchType, terms);
                el.hidden = !matched;
                if (matched) {
                    counter++;
                    const children = Array.from(el.querySelectorAll<HTMLElement>(data.target));
                    if ((children.length > 0) && children.every(child => child.hidden)) {
                        for (const child of children) {
                            child.hidden = false;
                        }
                    }
                }
            }

            if (e) { // only if the user is entering a search term
                const wrapper = input.closest(".lsf");
                if (wrapper) {
                    const count = wrapper.querySelector("[data-results-count]");
                    if (count) {
                        count.textContent = ""; // clear the live region so the screen reader reads the content even if it hasn't changed
                        count.textContent = `${counter} result${counter === 1 ? "" : "s"}.`;
                    }
                }
            }
        }
    }

    protected filterDebounced = _debounce(this.filter, LsFilterOptions.delay);

    protected match = (el: HTMLElement, matchType: LsFilterMatchTypes, terms: Array<string>) => {
        const data = el.dataset;
        const text = (data.keywords || el.textContent).trim().toLowerCase();
        const fn = (term: string) => text.indexOf(term.toLowerCase()) !== -1;
        switch (matchType) {
            case "all":
            case "exact":
            default:
                return terms.every(fn);
            case "any":
                return terms.some(fn);
        }
    }
}

export default LsFilter;