import $ = require("jquery");
import { Key } from "ts-keycode-enum";

import LsRecaptcha from "~/Src/Components/Recaptcha/Recaptcha";
import LsTransition from "~/Src/Components/Transition/Transition";

import "./OffCanvasMenu.scss";

const lsOffCanvasMenuFocusableSelector = ([
    'a[href]:not([tabindex="-1"]):not([aria-disabled="true"])',
    'button:not([tabindex="-1"]):not(:disabled)',
    'input:not([tabindex="-1"]):not(:disabled)',
    'select:not([tabindex="-1"]):not(:disabled)',
    'textarea:not([tabindex="-1"]):not(:disabled)',
    '[tabindex]:not([tabindex="-1"]):not(:disabled):not([aria-disabled="true"])'
]).join(", ");

class LsOffCanvasMenu {
    protected static _document: Document;
    protected static get document() {
        return LsOffCanvasMenu._document || (LsOffCanvasMenu._document = document);
    }

    protected static _$document: JQuery<Document>;
    protected static get $document() {
        return LsOffCanvasMenu._$document || (LsOffCanvasMenu._$document = $(LsOffCanvasMenu.document));
    }

    protected static _body: Element;
    protected static get body() {
        return LsOffCanvasMenu._body || (LsOffCanvasMenu._body = LsOffCanvasMenu.document.body);
    }

    protected static _$body: JQuery<Element>;
    protected static get $body() {
        return LsOffCanvasMenu._$body || (LsOffCanvasMenu._$body = $(LsOffCanvasMenu.body));
    }

    public readonly id: string;
    public readonly $element: JQuery;
    public readonly $basePanel: JQuery;
    protected $trigger: JQuery;
    protected panels: Array<LsOffCanvasMenuPanel> = [];
    protected static mousedownTarget: Element;
    protected static mouseupTarget: Element;

    public constructor(id: string, trigger: LsJQuerySelector) {
        this.id = id;
        this.$trigger = $(trigger);

        const $template = $(`[data-offcanvasmenu-template="${id}"]`);
        if ($template.length === 1) {
            const template = $template.html();
            if (template) {
                this.$element = $(template);
                this.$element.data("lsOffCanvasMenu", this);
                this.$basePanel = this.$element.find("[data-offcanvasmenu-panel]");
                if (this.$basePanel.length > 0) {
                    this.$element.on("keydown", this.onKeyDown);
                    this.$element.on("mousedown", this.onMouseDown);
                    this.$element.on("mouseup", this.onMouseUp);
                    this.$element.on("click", this.onMenuClick);
                    this.$element.on("click", '[data-action="offcanvasmenu-panel"]', this.onPanelTriggerClick);
                    LsOffCanvasMenu.$body.append(this.$element);
                }
            }
        }
    }

    public open = (setFocus: boolean) => {
        this.setAccessibilityAttributes(true);

        LsTransition.in(this.$element);
        LsTransition.in(this.$basePanel);

        if (setFocus) {
            let $elements = this.$basePanel.find(lsOffCanvasMenuFocusableSelector);
            $elements = $elements.filter(":visible");
            if ($elements.length > 0) {
                const $element = $elements.first();
                $element.trigger("focus");
            }
        } else {
            $(LsOffCanvasMenu.document.activeElement).trigger("blur");
        }
    }

    public close = (setFocus: boolean) => {
        if (setFocus) {
            this.$trigger.trigger("focus");
        } else {
            $(LsOffCanvasMenu.document.activeElement).trigger("blur");
        }

        this.setAccessibilityAttributes(false);

        for (const panel of this.panels.reverse()) {
            LsTransition.out(panel.$element);
        }
        LsTransition.out(this.$basePanel);
        LsTransition.out(this.$element);
    }

    protected togglePanel = (selector: LsJQuerySelector, setFocus: boolean) => {
        const $trigger = $(selector);
        if ($trigger.length === 1) {
            const data: LsJQueryData = $trigger.data();
            if (data.template) {
                const panels = this.panels.filter(p => p.id === data.template);
                if (panels.length > 0) {
                    this.closePanel(panels[0], setFocus);
                } else {
                    const panel = new LsOffCanvasMenuPanel(data.template, this, $trigger);
                    this.panels.push(panel);
                    panel.open(setFocus);
                }
            } else {
                const $panel = $trigger.closest("[data-offcanvasmenu-panel]");
                if ($panel.length > 0) {
                    this.closePanel($panel, setFocus);
                }
            }
        }
    }

    public closePanel = (selector: LsJQuerySelector | LsOffCanvasMenuPanel, setFocus: boolean) => {
        let panel: LsOffCanvasMenuPanel;
        if (selector instanceof LsOffCanvasMenuPanel) {
            panel = selector;
        } else {
            const $panel = $(selector);
            if ($panel.length > 0) {
                panel = $panel.data("LsOffCanvasMenuPanel");
            }
        }
        if (panel) {
            panel.close(setFocus);
            this.panels = this.panels.filter(p => p.id !== panel.id);
        }
    }

    protected onKeyDown = (e: LsJQueryEvent<HTMLElement, any, HTMLElement>) => {
        if ([Key.Space, Key.Enter, Key.RightArrow].indexOf(e.which) >= 0) {
            const selector = e.which === Key.RightArrow ? '[data-action="offcanvasmenu-panel"][data-template]' : '[data-action="offcanvasmenu-panel"]';
            const $trigger = $(e.target).closest(selector);
            if ($trigger.length > 0) {
                e.preventDefault();
                this.togglePanel($trigger, true);
            }
        } else if ([Key.Tab, Key.UpArrow, Key.DownArrow].indexOf(e.which) >= 0) {
            const $menu = $(e.currentTarget);
            const $panels = $menu.find("[data-offcanvasmenu-panel]");
            if ($panels.length > 0) {
                const $panel = $panels.last();
                let $elements = $panel.find(lsOffCanvasMenuFocusableSelector);
                $elements = $elements.filter(":visible");
                if (e.which === Key.Tab) {
                    const $close = $menu.find("[data-offcanvasmenu-close]").not("[data-offcanvasmenu-panel] [data-offcanvasmenu-close]");
                    if ($close.length > 0) {
                        $elements = $elements.add($close);
                    }
                }
                if ($elements.length > 0) {
                    e.preventDefault();

                    const $target = $(e.target).closest(lsOffCanvasMenuFocusableSelector);
                    const forward = (!e.shiftKey && (e.which === Key.Tab)) || (e.which === Key.DownArrow);
                    const firstIndex = 0;
                    const lastIndex = $elements.length - 1;
                    const oldIndex = $elements.index($target);
                    let newIndex: number;
                    if (oldIndex > -1) {
                        if ((oldIndex === firstIndex) && !forward) {
                            if (e.which === Key.Tab) {
                                newIndex = lastIndex;
                            }
                        } else if ((oldIndex === lastIndex) && forward) {
                            if (e.which === Key.Tab) {
                                newIndex = firstIndex;
                            }
                        } else if (forward) {
                            newIndex = oldIndex + 1;
                        } else {
                            newIndex = oldIndex - 1;
                        }
                    }

                    const $element = $elements.eq(newIndex);
                    $element.trigger("focus");
                }
            }
        }
    }

    protected onMouseDown = (e: LsJQueryEvent) => {
        LsOffCanvasMenu.mousedownTarget = e.target;
    }

    protected onMouseUp = (e: LsJQueryEvent) => {
        LsOffCanvasMenu.mouseupTarget = e.target;
    }

    protected onMenuClick = (e: LsJQueryEvent) => {
        if ([LsOffCanvasMenu.mousedownTarget, LsOffCanvasMenu.mouseupTarget, e.target].every(el => el === e.currentTarget)) {
            LsOffCanvasMenus.closeMenu(e.currentTarget, false);
        }
    }

    protected onPanelTriggerClick = (e: LsJQueryEvent) => {
        e.preventDefault();
        this.togglePanel(e.currentTarget, false);
    }

    protected setAccessibilityAttributes = (opening: boolean) => {
        const $trigger = $(`[data-action="offcanvasmenu"][data-template="${this.id}"]`);
        if ($trigger.length > 0) {
            $trigger.attr({
                "aria-expanded": String(opening),
                "aria-pressed": String(opening),
            });
        }
        this.$element.attr("aria-expanded", String(opening));
    }
}

class LsOffCanvasMenuPanel {
    protected static _document: Document;
    protected static get document() {
        return LsOffCanvasMenuPanel._document || (LsOffCanvasMenuPanel._document = document);
    }

    public readonly id: string;
    public readonly $element: JQuery;
    protected menu: LsOffCanvasMenu;
    protected $trigger: JQuery;

    public constructor(id: string, menu: LsOffCanvasMenu, trigger: LsJQuerySelector) {
        this.id = id;
        this.menu = menu;
        this.$trigger = $(trigger);

        const $template = $(`[data-offcanvasmenu-template="${id}"]`);
        if ($template.length === 1) {
            const template = $template.html();
            if (template) {
                this.$element = $(template);
                this.$element.data("LsOffCanvasMenuPanel", this);
                this.$element.on("keydown", this.onKeyDown);
                this.menu.$element.append(this.$element);

                const $forms = this.$element.find("form");
                if ($forms.length > 0) {
                    for (const form of $forms.toArray()) {
                        const $form = $(form) as JQuery<HTMLFormElement>;
                        $.validator.unobtrusive.parse($form);
                        LsRecaptcha.render($form);
                    }
                }
            }
        }
    }

    public open = (setFocus: boolean) => {
        this.setAccessibilityAttributes(true);
        LsTransition.in(this.$element);

        if (setFocus) {
            let $elements = this.$element.find(lsOffCanvasMenuFocusableSelector);
            $elements = $elements.filter(":visible");
            if ($elements.length > 0) {
                const $element = $elements.first();
                $element.trigger("focus");
            }
        } else {
            $(LsOffCanvasMenuPanel.document.activeElement).trigger("blur");
        }
    }

    public close = (setFocus: boolean) => {
        if (setFocus) {
            this.$trigger.trigger("focus");
        } else {
            $(LsOffCanvasMenuPanel.document.activeElement).trigger("blur");
        }

        this.setAccessibilityAttributes(false);

        LsTransition.out(this.$element);
    }

    protected onKeyDown = (e: LsJQueryEvent) => {
        if ((e.which === Key.LeftArrow) && !(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
            this.menu.closePanel(e.currentTarget, true);
        }
    }

    protected setAccessibilityAttributes = (opening: boolean) => {
        const $trigger = this.menu.$element.find(`[data-action="offcanvasmenu-panel"][data-template="${this.id}"]`);
        if ($trigger.length > 0) {
            $trigger.attr({
                "aria-expanded": String(opening),
                "aria-pressed": String(opening),
            });
        }
        this.$element.attr("aria-expanded", String(opening));
    }
}

export class LsOffCanvasMenus {
    protected static _$html: JQuery;
    protected static get $html() {
        return LsOffCanvasMenus._$html || (LsOffCanvasMenus._$html = $(LsOffCanvasMenus.document.documentElement));
    }

    protected static initialized = false;
    protected static document = document;
    protected static $document = $(LsOffCanvasMenus.document);
    protected static menus: Array<LsOffCanvasMenu> = [];

    public constructor() {
        if (!LsOffCanvasMenus.initialized) {
            $(() => {
                this.setAccessibilityAttributes();
            });
            LsOffCanvasMenus.$document.on("ajaxPageLoad", this.setAccessibilityAttributes);

            LsOffCanvasMenus.$document.on("keyup", this.onKeyUp);
            LsOffCanvasMenus.$document.on("keydown", '[data-action="offcanvasmenu"]', e => {
                if ((e.which === Key.Space) || (e.which === Key.Enter)) {
                    e.preventDefault();
                    this.toggleMenu(e.currentTarget, true);
                }
            });
            LsOffCanvasMenus.$document.on("click", '[data-action="offcanvasmenu"]', e => {
                e.preventDefault();
                this.toggleMenu(e.currentTarget, false);
            });

            LsOffCanvasMenus.initialized = true;
        }
    }

    public static closeMenu = (selector: LsJQuerySelector | LsOffCanvasMenu, setFocus: boolean) => {
        let menu: LsOffCanvasMenu;
        if (selector instanceof LsOffCanvasMenu) {
            menu = selector;
        } else {
            const $menu = $(selector);
            if ($menu.length > 0) {
                menu = $menu.data("lsOffCanvasMenu") as LsOffCanvasMenu;
            }
        }
        if (menu) {
            menu.close(setFocus);
            LsOffCanvasMenus.menus = LsOffCanvasMenus.menus.filter(m => m.id !== menu.id);
        }
        if (LsOffCanvasMenus.menus.length === 0) {
            LsOffCanvasMenus.unfreezePage();
        }
    }

    protected onKeyUp = (e: LsJQueryEvent<Document, any, Document>) => {
        if (e.which === Key.Escape) {
            const menu = LsOffCanvasMenus.menus[LsOffCanvasMenus.menus.length - 1]
            LsOffCanvasMenus.closeMenu(menu, true);
        }
    }

    protected setAccessibilityAttributes = () => {
        const $trigger = $('[data-action="offcanvasmenu"]:not(:disabled):not([aria-disabled="true"]:not([aria-expanded]):not([aria-pressed])');
        if ($trigger.length > 0) {
            $trigger.attr({
                "aria-expanded": "false",
                "aria-pressed": "false",
            });
        }
    }

    protected toggleMenu = (selector: LsJQuerySelector, setFocus: boolean) => {
        const $trigger = $(selector);
        const data: LsJQueryData = $trigger.data();
        if (data.template) {
            const menus = LsOffCanvasMenus.menus.filter(m => m.id === data.template);
            if (menus.length > 0) {
                LsOffCanvasMenus.closeMenu(menus[0], setFocus);
            } else {
                const menu = new LsOffCanvasMenu(data.template, $trigger);
                LsOffCanvasMenus.menus.push(menu);
                LsOffCanvasMenus.freezePage();
                menu.open(setFocus);
            }
        } else {
            const $menu = $trigger.closest("[data-offcanvasmenu]");
            if ($menu.length > 0) {
                LsOffCanvasMenus.closeMenu($menu, setFocus);
            }
        }
    }

    protected static freezePage = () => {
        LsOffCanvasMenus.$html.addClass("lsc-offcanvasmenu--open");
    }

    protected static unfreezePage = () => {
        LsOffCanvasMenus.$html.removeClass("lsc-offcanvasmenu--open");
    }
}

export default LsOffCanvasMenus;