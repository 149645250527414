import $ = require("jquery");

import LsApi from "~/Src/Components/Api/Api";
import { getCookie, setCookie } from "~/Src/Components/Utilities/Utilities";
import LsModals from "~/Src/Components/Modal/Modal";
import LsUdoManager from "~/Src/Components/Utilities/UdoManager";

interface LsSmsProperties {
    show?: any;
    cookie_name?: string;
    content_url?: string;
}

export class LsSms {
    protected static initialized = false;

    protected static _smsUdo: LsSmsProperties;
    public static get smsUdo() {
        return LsSms._smsUdo || (LsSms._smsUdo = LsUdoManager.get("sms"));
    }

    public constructor() {
        if (!LsSms.initialized) {
            if (LsSms.smsUdo && (LsSms.smsUdo.show === "undefined" || LsSms.smsUdo.show === true)) {
                const cookie = getCookie(LsSms.smsUdo.cookie_name);
                if (!cookie) {
                    LsSms.getModal();
                    setCookie(LsSms.smsUdo.cookie_name, "0", 365);
                }
            }
            LsSms.initialized = true;
        }
    }

    protected static getModal() {
        LsModals.openModalByUrl(LsSms.smsUdo.modal_url);
    }
}

export default LsSms;