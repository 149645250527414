export class LsCartItemReminder {
    public static observers: MutationObserver[] = [];
    public static cartCount: number;
    public static _window: Window;
    public static getSaleStatus: any;

    public static get window() {
        return LsCartItemReminder._window || (LsCartItemReminder._window = window);
    }

    protected static _document: Document;

    public static get document() {
        return LsCartItemReminder._document || (LsCartItemReminder._document = LsCartItemReminder.window.document);
    }

    public static IsMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(LsCartItemReminder.window.navigator.userAgent);
    }

    public static IsTest() {
        return LsCartItemReminder.window.location.pathname === "/jobs/test";
    }

    public constructor() {
        if (!LsCartItemReminder.IsMobile() && !LsCartItemReminder.IsTest()) {
            if (document.readyState === "loading") {
                document.addEventListener("DOMContentLoaded", LsCartItemReminder.appendCartNotification)
            } else {
                LsCartItemReminder.appendCartNotification();
            }
        }
    }

    public static waitFor(conditionFn, callback) {
        let interval = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 50;
        let expiration = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 20000;
        let conditionFnResult = conditionFn();
        if (conditionFnResult) {
            callback(conditionFnResult);
        }
        else if (expiration <= 0) {
        }
        else {
            expiration -= interval;
            return setTimeout(LsCartItemReminder.waitFor.bind(null, conditionFn, callback, interval, expiration), interval);
        }
    }

    public static removeAlert() {
        sessionStorage.setItem('LsCartItemReminder-closed', "");
        LsCartItemReminder.observers.forEach(function (observer) { return observer.disconnect(); });
        document.querySelector('#LsCartItemReminder').remove();

        let container = document.querySelector('.LsCartItemReminder-open');
        if (container) {
            container.classList.remove('LsCartItemReminder-open');
        }
    }

    public static connectObservers() {
        if (LsCartItemReminder.observers.length !== 0) {
            LsCartItemReminder.observers.forEach((observer) => { return observer.disconnect(); });
        }

        let targetNodes = Array.from(document.querySelectorAll('#lsCartPanel, #shopping-cart-menu, .lsx-stickyheader a[href="/account"], #login-panel, #shopping-cart-menu-wrapper, .lsx-cartpanel-list, .lsc-amm-menus, .lsc-amm, .mega-menu'));

        LsCartItemReminder.observers = targetNodes.map(function (targetNode) {
            let observer = new MutationObserver(function () {

                let anyIsOpen = targetNodes.some(function (el) { return el.classList.contains('ls-popover-active') || el.getAttribute('aria-expanded') === 'true' || el.classList.contains('lsc-amm--open'); });

                let cartItemReminder = document.querySelector('#LsCartItemReminder');

                if (anyIsOpen && cartItemReminder) {
                    cartItemReminder.classList.add('hide');
                }
                else if (!anyIsOpen && cartItemReminder) {
                    cartItemReminder.classList.remove('hide');
                }

                LsCartItemReminder.waitFor(function () { return document.querySelector('[cart-item-count], [data-cart-qty]'); }, function (el) {
                    let count = parseInt(el.textContent, 10);

                    if (!count) {
                        LsCartItemReminder.removeAlert();
                    }
                    else if (!sessionStorage.getItem('LsCartItemReminder-closed') && count !== LsCartItemReminder.cartCount) {
                        LsCartItemReminder.appendCartNotification();
                    }
                });
            });

            observer.observe(targetNode, { attributes: true, childList: true });
            return observer;
        });
    }

    public static appendCartNotification(): void {
        if (sessionStorage.getItem('LsCartItemReminder-closed') !== null) {
            return;
        }
       
        LsCartItemReminder.cartCount = parseInt(document.querySelector("[data-cart-qty]").innerHTML);

        if (LsCartItemReminder.cartCount !== 0) {
            let hasSaleItem = !!document.querySelector('.ls-header-cart-item .offering-sale-price, .lsx-cartpanel-list .lsg .lsu-c-rubyRed');
            
            let notificationEl = document.querySelector('#LsCartItemReminder');
            let message;

            if (hasSaleItem) {
                message = 'Sale ends soon for items in your cart!';
            }
            else {
                message = 'Don\'t forget, you have items in your cart!';
            }

            if (notificationEl) {
                if (hasSaleItem) {
                    notificationEl.classList.add('is-sale');
                } else {
                    notificationEl.classList.remove('is-sale');
                }
                notificationEl.querySelector('.message').innerHTML = message;
            } else {
                let cartMessageContainer = document.createElement("div");
                cartMessageContainer.setAttribute("id", "LsCartItemReminder");
                let messageElement = document.createElement("div");
                messageElement.classList.add("message");
                messageElement.textContent = message;
                let buttonElement = document.createElement("button");
                buttonElement.classList.add("cart-alert-close");
                buttonElement.setAttribute("type", "button");
                buttonElement.setAttribute("aria-label", "close notification");
                buttonElement.textContent = "×";
                cartMessageContainer.appendChild(messageElement);
                cartMessageContainer.appendChild(buttonElement);

                let dataCartPanel = document.querySelector("[data-cart-panel-refresh]");
                dataCartPanel.appendChild(cartMessageContainer);

                document.body.classList.add('LsCartItemReminder-open');

                if (hasSaleItem) {
                    document.querySelector('#LsCartItemReminder').classList.add('is-sale');
                }
                else {
                    document.querySelector('#LsCartItemReminder').classList.remove('is-sale');
                }
                document.querySelector('#LsCartItemReminder .cart-alert-close').addEventListener('click', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    LsCartItemReminder.removeAlert();
                });
                LsCartItemReminder.connectObservers();
            }
        }
    }
}

export default LsCartItemReminder;