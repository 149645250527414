import $ = require("jquery");
import LsUdoManager from "~/Src/Components/Utilities/UdoManager";

class NewsletterSignupModel {
    emailAddress: any;
}

export class LsListrak {
    protected static initialized = false;

    protected static _window: Window;
    protected static get window() {
        return LsListrak._window || (LsListrak._window = window);
    }

    protected static _document: Document;
    protected static get document(): Document {
        return LsListrak._document || (LsListrak._document = LsListrak.window.document);
    }

    protected static _newsLetterUrl: string;
    protected static get newsLetterUrl(): string {
        if (LsListrak._newsLetterUrl) {
            return LsListrak._newsLetterUrl;
        } else {
            const newsLetterUdo = LsUdoManager.get("newsLetter");
            if (newsLetterUdo) {
                const url = newsLetterUdo.post_url;
                if (url) {
                    LsListrak._newsLetterUrl = url;
                    return LsListrak._newsLetterUrl;
                }
            } else {
                LsListrak._newsLetterUrl = "/newsletter/listraksubscribe";
                return LsListrak._newsLetterUrl;

            }
        }

        return LsListrak._newsLetterUrl;
    }

    public constructor() {
        if (!LsListrak.initialized) {
            LsListrak.setupEventListener();

            LsListrak.initialized = true;
        }
    }

    public static setupEventListener() {
        LsListrak.document.addEventListener("ltkSubscribe", function (e: any) {
            var model: NewsletterSignupModel = {
                emailAddress: e.detail.email,
            };

            if (model.emailAddress && model.emailAddress.includes("@")) {
                LsListrak.signupForNewsletter(LsListrak.newsLetterUrl, model);
            }
        });
    }

    protected static signupForNewsletter(url: string, model: NewsletterSignupModel) {
        $.ajax({
            method: "POST",
            global: false,
            url,
            data: model,
            success: (result, status, xhr) => {
                console.log("Subscribed successfully through listrak.");
            },
            error: (xhr, status, err) => {
                console.log("An error has occurred.");
            }
        });
    }
}

export default LsListrak;