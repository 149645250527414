import $ = require("jquery");

import LsApi from "~/Src/Components/Api/Api";
import { LsBreakpoint, LsBreakpoints } from "~/Src/Components/Breakpoints/Breakpoints";
import { getCookie, setCookie } from "~/Src/Components/Utilities/Utilities";
import LsModals from "~/Src/Components/Modal/Modal";
import LsDrawer from "~/Src/Components/Drawer/Drawer";
import LsUdoManager from "~/Src/Components/Utilities/UdoManager";

import "./NewsletterPopTart.scss";

interface LsNewsletterProperties {
    show?: any;
    cookie_name?: string;
    content_url?: string;
}

export class LsNewsletter {
    protected static initialized = false;

    protected static _newsletterUdo: LsNewsletterProperties;
    public static get newsletterUdo() {
        return LsNewsletter._newsletterUdo || (LsNewsletter._newsletterUdo = LsUdoManager.get("newsletter"));
    }

    public constructor() {
        if (!LsNewsletter.initialized) {
            if (LsNewsletter.newsletterUdo && (LsNewsletter.newsletterUdo.show === "undefined" || LsNewsletter.newsletterUdo.show === true)) {
                const cookie = getCookie(LsNewsletter.newsletterUdo.cookie_name);
                if (!cookie) {
                    const lgScreen = LsBreakpoints.matches(LsBreakpoint.spf40);
                    if (lgScreen) {
                        LsNewsletter.getModal();
                    } else {
                        LsNewsletter.getPopup();
                    }
                    setCookie(LsNewsletter.newsletterUdo.cookie_name, "0", 365);
                } 
            }
            LsNewsletter.initialized = true;
        }
    }

    protected static getPopup() {
        LsNewsletterPopTart.getPopTart();

    }

    protected static getModal() {
        LsModals.openModalByUrl(LsNewsletter.newsletterUdo.modal_url);
    }
}

export class LsNewsletterPopTart {
    public static getPopTart() {
        LsApi.ajax({
            method: "GET",
            url: LsNewsletter.newsletterUdo.poptart_url,
            global: false,
            success: (response, status, xhr) => {
                LsApi.onSuccess(response, status, xhr);
                LsDrawer.open("[data-newsletter-poptart]");
            },
            error: LsApi.onError,
        });
    }
}

export default { LsNewsletter, LsNewsletterPopTart };