interface ILsCountdownTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export class LsCountdownClock {
    protected static window = window;
    protected static document = document;

    protected el: HTMLElement;
    protected days: HTMLElement;
    protected hours: HTMLElement;
    protected minutes: HTMLElement;
    protected seconds: HTMLElement;
    protected end = 0;
    protected previous = 0;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.days = this.el.querySelector<HTMLElement>("[data-countdown-clock-days]");
        this.hours = this.el.querySelector<HTMLElement>("[data-countdown-clock-hours]");
        this.minutes = this.el.querySelector<HTMLElement>("[data-countdown-clock-minutes]");
        this.seconds = this.el.querySelector<HTMLElement>("[data-countdown-clock-seconds]");
        const now = Math.floor((new Date()).getTime() / 1000);
        const difference = parseInt(this.el.dataset.countdownClock ?? "0");
        this.end = now + difference;
        this.run();
    }

    protected run = () => {
        const now = Math.floor((new Date()).getTime() / 1000);
        const remaining = this.end - now;
        if (remaining > 0) {
            if (now !== this.previous) {
                const time = this.split(remaining);
                this.update(time);
                this.previous = now;
            }

            LsCountdownClock.window.requestAnimationFrame(this.run);
        }
    }

    protected split = (seconds: number): ILsCountdownTime => {
        const days = Math.floor(seconds / 86400);
        let remainder = seconds % 86400;
        const hours = Math.floor(remainder / 3600);
        remainder = remainder % 3600;
        const minutes = Math.floor(remainder / 60);
        remainder = remainder % 60;

        return { days, hours, minutes, seconds: remainder };
    }

    protected update = (time: ILsCountdownTime) => {
        if (this.days) {
            if (time.days === 0) {
                if (!this.days.hidden) {
                    const unit = this.days.closest<HTMLElement>("[data-countdown-clock-unit]");
                    if (unit) {
                        unit.hidden = true;
                    }
                    this.days.hidden = true;
                }
            } else {
                this.days.textContent = String(time.days);
            }
        }

        if (this.hours) {
            this.hours.textContent = String(time.hours);
        }

        if (this.minutes) {
            this.minutes.textContent = String(time.minutes);
        }

        if (this.seconds) {
            this.seconds.textContent = String(time.seconds);
        }
    }
}

export class LsCountdownClocks {
    protected static document = document;

    public constructor() {
        if (LsCountdownClocks.document.readyState === "loading") {
            LsCountdownClocks.document.addEventListener("DOMContentLoaded", LsCountdownClocks.init);
        } else {
            LsCountdownClocks.init();
        }
    }

    protected static init() {
        const elements = Array.from(LsCountdownClocks.document.querySelectorAll<HTMLElement>("[data-countdown-clock]"));
        for (const el of elements) {
            if (el.dataset.coundownClockInitialized !== "true") {
                new LsCountdownClock(el);
                el.dataset.coundownClockInitialized = "true";
            }
        }
    }
}

export default LsCountdownClocks;