import $ = require("jquery");
import LsModals from "~/Src/Components/Modal/Modal";
import LsLogger from "../Logging/Logger";

export class LsReturnModal {
    protected static _window: Window;
    public static get window() {
        return LsReturnModal._window || (LsReturnModal._window = window);
    }

    protected static _document: Document;
    public static get document() {
        return LsReturnModal._document || (LsReturnModal._document = LsReturnModal.window.document);
    }

    protected static _url: string;
    public static get url() {
        if (LsReturnModal._url === undefined) {
            var href = LsReturnModal.window.location.href;
            let decodedURL = LsReturnModal.decodeUrl(href, "rtm", 4)
            if (decodedURL === undefined) {
                return LsReturnModal._url;
            }

            return LsReturnModal._url = decodedURL;
        }
    }

    public constructor() {
        if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", LsReturnModal.getModal)
        } else {
            LsReturnModal.getModal();
        }
    }

    public static decodeUrl(url: string, param: string, num: number) {
        let value: string;

        if (url.indexOf(param) != -1) {
            let decodedURL = decodeURIComponent(url);
            let newURL = decodedURL.indexOf(param);
            let subString = decodedURL.substring(newURL + num);
            let result = decodeURIComponent(subString);

            value = result;
        }

        return value;
    }

    // remove rtm and its value and return url to set as href on location
    public static stripRTM(url: string) {
        let decodedURL = decodeURIComponent(url);
        let value = decodedURL.split("?")[0];

        return value;
    }

    protected static getModal() {
        let url = LsReturnModal.url;

        if (url !== undefined) {
            LsModals.openModalByUrl(url);
            LsReturnModal.window.history.pushState(null, null, LsReturnModal.stripRTM(LsReturnModal.window.location.href));
        }

    }
}

export default LsReturnModal;