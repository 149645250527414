import $ = require("jquery");

import LsTransition from "~/Src/Components/Transition/Transition";
import { debounce } from "~/Src/Components/Utilities/Utilities";

import "./BackToTop.scss";

class LsBackToTopOptions {
    public static selector = "#lsBackToTop";
    public static scrollDistance = 300;
    //public static debounceWait = 100;
}

export class LsBackToTop {
    protected static window = window;
    protected static initialized = false;
    protected static visible = false;

    public constructor() {
        if (!LsBackToTop.initialized) {
            this.toggleVisibility();

            $(() => {
                this.toggleVisibility();
            });

            $(LsBackToTop.window).on("scroll", debounce(this.toggleVisibility));

            LsBackToTop.initialized = true;
        }

    }

    protected toggleVisibility = () => {
        const pos = LsBackToTop.window.pageYOffset;
        if (!LsBackToTop.visible && (pos >= LsBackToTopOptions.scrollDistance)) {
            this.show(LsBackToTopOptions.selector);
        } else if (LsBackToTop.visible && (pos < LsBackToTopOptions.scrollDistance)) {
            this.hide(LsBackToTopOptions.selector);
        }
    }

    protected show = (selector: LsJQuerySelector) => {
        LsTransition.in(selector);
        LsBackToTop.visible = true;
    }

    protected hide = (selector: LsJQuerySelector) => {
        LsTransition.out(selector);
        LsBackToTop.visible = false;
    }
}

export default LsBackToTop;