import $ = require("jquery");

import emojiRegex = require("emoji-regex/text.js");

import LsLogger from "~/Src/Components/Logging/Logger";
import LsTextField from "~/Src/Components/TextField/TextField";

interface ILsSuppressCharacterType {
    readonly name: string;
    readonly pattern: RegExp;
    readonly exceptions?: RegExp;
    readonly keyCode?: number;
    readonly replacement?: string,
}

export class LsSuppress {
    // ReSharper disable InconsistentNaming
    protected static _$document: JQuery<Document>;
    protected static get $document() {
        return LsSuppress._$document || (LsSuppress._$document = $(document));
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;
    protected static readonly characterTypes: Array<ILsSuppressCharacterType> = [
        {
            name: "spaces",
            pattern: /\s/g,
            keyCode: 32,
        },
        {
            name: "emoji",
            pattern: emojiRegex(),
            exceptions: /[\d#*]/,
        },
    ];

    public constructor() {
        if (!LsSuppress.initialized) {
            // Chrome and Firefox follow the spec which disallows getting/setting selection in certain types of input elements
            // https://stackoverflow.com/questions/21177489/selectionstart-selectionend-on-input-type-number-no-longer-allowed-in-chrome
            // https://stackoverflow.com/questions/22381837/how-to-overcome-whatwg-w3c-chrome-version-33-0-1750-146-regression-bug-with-i/24247942

            const selectors = this.createSelectors(LsSuppress.characterTypes);
            const keydownCharacterTypes = LsSuppress.characterTypes.filter(ct => ct.keyCode);
            const keydownSelectors = this.createSelectors(keydownCharacterTypes);

            LsSuppress.$document.on("keydown", keydownSelectors, e => {
                for (const ct of keydownCharacterTypes) {
                    if (ct.keyCode && (e.which === ct.keyCode)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        const id = LsLogger.getIdentifier(e.currentTarget);
                        LsLogger.trace(`Suppressing ${ct.name} [${id}]`);
                    }
                }
            });

            LsSuppress.$document.on("input", selectors, e => {
                if (LsTextField.supportsSelection(e.currentTarget)) {
                    this.suppress(e.currentTarget);
                }
            });

            LsSuppress.$document.on("blur", selectors, e => this.suppress(e.currentTarget));

            $(() => {
                const $elements = $(selectors);
                for (const el of $elements.toArray()) {
                    this.suppress(el);
                }
            });

            LsSuppress.initialized = true;
        }
    }

    protected createSelectors = (characterTypes: Array<ILsSuppressCharacterType>) => {
        const selectors = characterTypes.map(ct => `[data-suppress~="${ct.name}"]`);
        return selectors.join(", ");
    }

    protected suppress(el: LsJQuerySelector) {
        const $el = $(el);

        if (!LsTextField.isTextField(el)) {
            el = $el.get(0);

            if (!LsTextField.isTextField(el)) {
                return;
            }
        }

        const suppress: string = $el.data("suppress");
        if (!suppress) {
            return;
        }
        const names = suppress.split(" ");

        const value = $el.val() as string;
        if (!value) {
            return;
        }
        let suppressed = value;

        let caretPos = LsTextField.getCaretPosition(el);
        for (const ct of LsSuppress.characterTypes) {
            if (names.indexOf(ct.name) === -1) {
                continue;
            }

            let replaced = 0;
            suppressed = suppressed.replace(ct.pattern, (match, ...args) => {
                if (ct.exceptions && ct.exceptions.test(match)) {
                    return match;
                }

                const id = LsLogger.getIdentifier($el.get(0));
                const code = match.replace(/./g, c => "\\u" + `000${c.charCodeAt(0).toString(16)}`.slice(-4));
                LsLogger.trace(`Suppressing ${ct.name} [${id}] [character=${match}] [code=${code}]` + (ct.replacement ? ` [replacement=${ct.replacement}]` : ""));

                const offset: number = args[args.length - 2];

                if (ct.replacement) {
                    if (caretPos >= offset - replaced) {
                        caretPos += ct.replacement.length - match.length;
                    }
                    replaced += match.length - ct.replacement.length;
                    return ct.replacement;
                }

                if (caretPos > offset - replaced) {
                    caretPos -= match.length;
                }
                replaced += match.length;
                return "";
            });
        }

        if (suppressed !== value) {
            $el.val(suppressed);
            if (LsTextField.supportsSelection(el)) {
                LsTextField.setCaretPosition(el, caretPos);
            }
        }
    }
}

export default LsSuppress;