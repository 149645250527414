import "./Header.scss";

const w = window;
const d = document;

const header = d.querySelector<HTMLElement>("[data-sticky-header]");
if (header) {
    const className = header.dataset.stickyHeader;
    const headerBoundary = header.getBoundingClientRect();
    const headerTop = headerBoundary.top + w.pageYOffset;

    const toggleSticky = () => {
        const pos = w.pageYOffset;
        const stuck = d.documentElement.classList.contains(className);
        if ((pos > 0) && (pos >= headerTop)) {
            if (!stuck) {
                d.documentElement.classList.add(className);
                header.style.top = "0";
            }
        } else if (stuck) {
            d.documentElement.classList.remove(className);
        }
    };

    w.addEventListener("scroll", () => {
        w.requestAnimationFrame(toggleSticky);
    });

    toggleSticky();
}