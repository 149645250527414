import $ = require("jquery");

import "normalize.css/normalize.css";
import "~/Src/SharedStyles/fonts.scss";
import "~/Src/SharedStyles/defaults.scss";
import "~/Src/SharedStyles/utilities.scss";
import "~/Src/SharedStyles/buttons.scss";
import "~/Src/SharedStyles/icons.scss";
import "~/Src/SharedStyles/forms.scss";
import "~/Src/SharedStyles/grid.scss";
import "~/Src/SharedStyles/lists.scss";
import "~/Src/SharedStyles/tables.scss";
import "~/Src/Components/Badge/Badge.scss";
import "~/Src/Components/Notification/Notification.scss";
import "./Header";

require("jquery-ajax-unobtrusive");
require("jquery-validation");
require("jquery-validation-unobtrusive");

import "element-closest/browser";
if (!Array.from) {
    Array.from = require("array-from");
}

$.ajaxSetup({
    cache: false,
    headers: { "X-Requested-From": "ls-responsive" },
});

import LsApi from "~/Src/Components/Api/Api";
import LsBackToTop from "~/Src/Components/BackToTop/BackToTop";
import LsCountdownClocks from "~/Src/Components/CountdownClock/CountdownClock";
import LsDrawer from "~/Src/Components/Drawer/Drawer";
import LsOrdergroove from "~/Src/Components/Ordergroove/Ordergroove";
import LsEvents from "~/Src/Components/Events/Events";
import LsHint from "~/Src/Components/Hint/Hint";
import LsInputMask from "~/Src/Components/TextField/InputMask";
import LsListrak from "~/Src/Components/Listrak/Listrak";
import LsLiveChat from "~/Src/Components/LiveChat/LiveChat";
import LsLogger from "~/Src/Components/Logging/Logger";
import AccessibleMegaMenus from "~/Src/Components/Navigation/AccessibleMegaMenu";
import LsOffCanvasMenus from "~/Src/Components/Navigation/OffCanvasMenu";
import LsModals from "~/Src/Components/Modal/Modal";
import { LsNewsletter, LsNewsletterPopTart } from "~/Src/Components/Newsletter/Newsletter";
import LsPasswordStrength from "~/Src/Components/PasswordStrength/PasswordStrength";
import LsRecaptcha from "~/Src/Components/Recaptcha/Recaptcha";
import LsScrollShadows from "~/Src/Components/Scroll/ScrollShadows";
import LsAutoComplete from "~/Src/Components/Search/AutoComplete";
import LsFilter from "~/Src/Components/Search/Filter";
import LsShowPassword from "~/Src/Components/ShowPassword/ShowPassword";
import LsSms from "~/Src/Components/Listrak/Sms";
import LsSuppress from "~/Src/Components/TextField/Suppress";
import LsToggle from "~/Src/Components/Toggle/Toggle";
import LsToggleClass from "~/Src/Components/ToggleClass/ToggleClass";
import LsTransition from "~/Src/Components/Transition/Transition";
import LsReturnModal from "~/Src/Components/Utilities/UrlDecodedModal";
import LsValidators from "~/Src/Components/Validators/Validators";
import LsGuardedDisable from "../../Components/Utilities/GuardedDisable";
import LsLoading from "../../Components/Loading/Loading";
import { setUpRemoveFromCartFunction } from "~/Src/Components/Utilities/RemoveFromCart";
import LsCartItemReminder from "~/Src/Components/Utilities/CartItemReminder";

var va = new LsValidators();

var am = new AccessibleMegaMenus();
var ai = new LsApi();
var ac = new LsAutoComplete();
var bt = new LsBackToTop();
var cc = new LsCountdownClocks();
var dr = new LsDrawer();
var og = new LsOrdergroove();
var ev = new LsEvents();
var fi = new LsFilter();
var hi = new LsHint();
var im = new LsInputMask();
var lt = new LsListrak();
var lc = new LsLiveChat();
var lg = new LsLogger();
var mo = new LsModals();
var nw = new LsNewsletter();
var om = new LsOffCanvasMenus();
var ps = new LsPasswordStrength();
var sh = new LsScrollShadows();
var sp = new LsShowPassword();
var sm = new LsSms();
var ss = new LsSuppress();
var tg = new LsToggle();
var tc = new LsToggleClass();
var tr = new LsTransition();
var dm = new LsReturnModal();
var cr = new LsCartItemReminder();

// todo - move to a third party initialization file
window.grecaptcha = window.grecaptcha || undefined;

(window as any).onGoogleRecaptchaApiReady = () => {
    const rc = new LsRecaptcha();
};

$(document).on("modalInit", "[data-modal]", e => {
    const modal = e.currentTarget;
    const $imgs: JQuery<HTMLImageElement> = $(modal).find("img");
    if ($imgs.length > 0) {
        for (const img of $imgs.toArray()) {
            img.addEventListener("load", e => {
                LsScrollShadows.toggleAllShadows(modal);
            })
        }
    }
});

document.addEventListener("submit", e => {
    if (e.target instanceof Element) {
        const form = e.target.closest("form");
        if (form instanceof HTMLFormElement) {
            const validForm = $(form).valid();
            if (validForm) {
                if (form.dataset.ajax !== "true") {
                    LsLoading.showOverlay();
                }

                const selector = '[type="submit"]';
                const btns: LsJQuerySelector = Array.from(form.querySelectorAll<HTMLElement>(selector));
                const formId = form.id;
                if (formId) {
                    const extFormSubmits: LsJQuerySelector = Array.from(document.querySelectorAll<HTMLElement>(`${selector}[form="${formId}"]`));
                    btns.push(...extFormSubmits)
                }

                if (window.location.href.indexOf("/orders") === -1) {
                    LsGuardedDisable.disable(btns, "LsSubmitDisable");
                }
            }
        }
    }
});

// todo - move to more accessible global space sometime soon 
(window as any).LoginModalOnInit = (modal: Element) => {
    LsRecaptcha.render(modal);
}
(window as any).RegisterModalOnInit = (modal: Element) => {
    LsRecaptcha.render(modal);
}
(window as any).ReloadOnClose = () => {
    window.location.reload();
}

setUpRemoveFromCartFunction();

$(document).on("keydown", 'a[role="button"]', e => {
    if (e.which === 32) {
        $(e.currentTarget).trigger("click");
    }
});

$(document).on("modalInit", "[data-modal]", e => {
    const $modal = $(e.currentTarget);
    const $forms: JQuery<HTMLFormElement> = $modal.find("form");
    if ($forms.length > 0) {
        for (const form of $forms.toArray()) {
            const $form = $(form);
            let validator: JQueryValidation.Validator = $form.data("validator");
            if (!validator) {
                $.validator.unobtrusive.parse(form);
                validator = $form.data("validator");
            }
        }
    }
});

$(document).on("click", '[data-action="mobileAppBack"]', e => {
    window.history.back();
});