import $ = require("jquery");

//added for code review

import LsApiUtilities from "./ApiUtilities";
import { ILsJsonResult, LsJsonResultManager } from "./JsonResult";
import { ILsStandardJsonResponse, LsStandardJsonResponseManager } from "./StandardJsonResponse";
import LsLoading from "~/Src/Components/Loading/Loading";
import { LsLogger, LsLoggerLevels } from "~/Src/Components/Logging/Logger";
import LsModals from "~/Src/Components/Modal/Modal";
import LsRecaptcha from "~/Src/Components/Recaptcha/Recaptcha";

export interface ILsApiAjaxSettings extends JQuery.AjaxSettings {
    LsIsAjaxUnobtrusiveRequest?: boolean;
    LsIsPost?: boolean;
    LsIsRequestedFromModal?: boolean;
    LsLogBeforeRequest?: boolean;
    LsLogAfterRequest?: boolean;
}

export type LsApiSuccessCallback = (data: any, textStatus: JQuery.Ajax.SuccessTextStatus, jqXHR: JQuery.jqXHR, settings?: ILsApiAjaxSettings) => void;

export class LsApi {
    protected static _window: Window;
    protected static get window() {
        return LsApi._window || (LsApi._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsApi._document || (LsApi._document = document);
    }

    protected static initialized = false;
    protected static postTraceLogs = false;

    public constructor() {
        if (!LsApi.initialized) {
            $.ajaxPrefilter(LsApi.onPrefilter);

            const $document = $(LsApi.document);
            $document.on("ajaxStart", LsApi.onStart);
            $document.on("ajaxSend", LsApi.onSend);
            $document.on("ajaxComplete", LsApi.onComplete);

            (LsApi.window as any).AjaxFormOnSuccess = LsApi.ajaxFormOnSuccess;
            (LsApi.window as any).AjaxFormOnFailure = LsApi.ajaxFormOnFailure;

            LsApi.initialized = true;
        }
    }

    public static ajax(settings: ILsApiAjaxSettings) {
        LsApi.modifySuccessCallback(settings); // error callback is modified in prefilter
        if (LsApiUtilities.isPost(settings)) {
            LsApiUtilities.addAntiForgeryToken(settings);
            if (LsApi.postTraceLogs) {
                LsApiUtilities.addTraceLogs(settings);
            }
            if (LsApiUtilities.isRequestedFromModal(settings)) {
                LsApiUtilities.addRequestedFromModal(settings);
            }
        }
        LsApiUtilities.addRequestId(settings);
        LsApiUtilities.addViewportSize(settings);
        LsApiUtilities.addUtcOffset(settings);
        return $.ajax(settings);
    }

    public static enableTraceLogs() {
        LsLogger.trace("Enabling adding of trace logs to POST requests.");
        LsApi.postTraceLogs = true;
    }

    public static disableTraceLogs() {
        LsLogger.trace("Disabling adding of trace logs to POST requests.");
        LsApi.postTraceLogs = false;
    }

    public static onSuccess(result: ILsJsonResult | ILsStandardJsonResponse, status: JQuery.Ajax.SuccessTextStatus, xhr: JQuery.jqXHR, settings?: ILsApiAjaxSettings) {
        if (xhr.responseJSON) {
            if (LsJsonResultManager.isJsonResult(result)) {
                LsJsonResultManager.run(result, status, xhr, settings);
            } else if (LsStandardJsonResponseManager.isStandardJsonResponse(result)) {
                LsStandardJsonResponseManager.run(result, status, xhr, settings);
            }
        } else if (settings) {
            const requestId = LsApiUtilities.getRequestId(settings);
            LsLogger.log(`Not a JSON response [requestId=${requestId}] [url=${settings.url}]`, "error");
        }
    }

    public static onError(xhr: JQuery.jqXHR, status: JQuery.Ajax.ErrorTextStatus, err: string) { }

    protected static ajaxFormOnSuccess(result: ILsJsonResult | ILsStandardJsonResponse, status: JQuery.Ajax.SuccessTextStatus, xhr: JQuery.jqXHR) {
        LsApi.onSuccess(result, status, xhr);
    }

    protected static ajaxFormOnFailure(xhr: JQuery.jqXHR, status: JQuery.Ajax.ErrorTextStatus, err: string) {
        LsApi.onError(xhr, status, err);

        if (("LsRecaptcha" in window)) {
            LsRecaptcha.reset();
        }
    }

    protected static onPrefilter(settings: ILsApiAjaxSettings) {
        if (LsApiUtilities.isAjaxUnobtrusiveRequest(settings)) {
            LsApiUtilities.addAjaxUnobtrusiveContext(settings);
            LsApi.modifySuccessCallback(settings);
        }
        LsApi.modifyErrorCallback(settings);
        LsApiUtilities.addRequestId(settings);
        LsApiUtilities.addViewportSize(settings);
        LsApiUtilities.addUtcOffset(settings);
    }

    protected static onStart() {
        LsLoading.showOverlay();
    }

    protected static onSend(e: JQuery.TriggeredEvent, xhr: JQuery.jqXHR, settings: ILsApiAjaxSettings) {
        if (LsApiUtilities.isAjaxUnobtrusiveRequest(settings)) {
            if (LsApi.postTraceLogs) {
                LsApiUtilities.addTraceLogs(settings);
            }
            if (LsApiUtilities.isRequestedFromModal(settings)) {
                LsApiUtilities.addRequestedFromModal(settings, xhr);
            }
        }
        if (settings.LsLogBeforeRequest) {
            const requestId = LsApiUtilities.getRequestId(settings);
            LsLogger.trace(`Sending AJAX request [requestId=${requestId}] [url=${settings.url}]`);
        }
    }

    protected static onComplete(e: JQuery.TriggeredEvent, xhr: JQuery.jqXHR, settings: ILsApiAjaxSettings) {
        if (LsJsonResultManager.isJsonResult(xhr.responseJSON)) {
            const result = xhr.responseJSON;
            if (!(result.actions?.redirect || result.actions?.refresh || result.actions?.submitForm)) {
                LsLoading.hideOverlay();
            }
        } else if (LsStandardJsonResponseManager.isStandardJsonResponse(xhr.responseJSON)) {
            const response = xhr.responseJSON;
            if (Array.isArray(response.actions) && !response.actions.some(action => {
                switch (action.type) {
                    case "execute":
                        switch (action.mode) {
                            case "redirect":
                            case "refresh":
                                return true;

                        }
                        break;
                    case "load":
                        switch (action.mode) {
                            case "modal":
                                return true;
                        }
                        break;
                }
                return false;
            })) {
                LsLoading.hideOverlay();
            }
        } else {
            LsLoading.hideOverlay();
        }

        if (settings.LsLogAfterRequest) {
            const requestId = LsApiUtilities.getRequestId(settings);
            LsLogger.trace(`AJAX request is complete [requestId=${requestId}] [url=${settings.url}] [statusCode=${xhr.status} ${xhr.statusText}] [response=${LsLogger.excerpt(xhr.responseText, 200, false, true)}]`);
        }
    }

    protected static handleError(xhr: JQuery.jqXHR, status: JQuery.Ajax.TextStatus, err: string, settings: ILsApiAjaxSettings) {
        let title: string;
        let content: string;
        let target: string;
        let level: LsLoggerLevels = "trace";

        if (status === "abort") {
            return;
        }

        switch (xhr.status) {
            case 0:
            case 502:
            case 503:
            case 504:
                target = "#lsNetworkIssueNotification";
                break;
            case 400:
                title = "Error";
                content = xhr.responseText;
                break;
            case 429:
                title = xhr.getResponseHeader("X-LS-T") || "Whoa! You're too quick for us!";
                content = xhr.getResponseHeader("X-LS-M") || "Please wait a few minutes and give it another try.";
                break;
            case 200:
            case 500:
                title = xhr.getResponseHeader("X-LS-T") || "Error";
                content = xhr.getResponseHeader("X-LS-M") || (xhr.getResponseHeader("X-LS-T") ? "" : "An unknown error has occurred.");
                break;
            default:
                title = "An unknown error has occurred.";
        }

        if (status === "parsererror") {
            level = "error";
        }

        const requestId = LsApiUtilities.getRequestId(settings);

        if (!LsLogger.isGlobalLoggerUrl(settings.url)) {
            LsLogger.log(`Api.handleError [requestId=${requestId}] [url=${settings.url}] [statusCode=${xhr.status} ${xhr.statusText}] [ajaxStatus=${status}] [xhrStatus=${xhr.readyState} ${["UNSENT", "OPENED", "HEADERS_RECEIVED", "LOADING", "DONE"][xhr.readyState]}] [contentEncoding=${xhr.getResponseHeader("Content-Encoding")}]`, level);
        }

        if (LsApiUtilities.global(settings)) {
            if (target) {
                LsModals.openModalByTarget(target);
            } else {
                LsModals.openErrorNotificationModal(title, content ? `<div>${content}</div>` : "", { destroyOnClose: true });
            }
        }
    }

    protected static modifySuccessCallback(settings: ILsApiAjaxSettings) {
        // only set up default success callback if the ajax settings already have a success callback
        // so that ajax calls that don't care about a response don't break on a non (JSON) response
        // if that assumption changes (that ajax calls without a success callback don't need this default handling),
        //   then handle that case differently
        if (settings.success) {
            let success: JQuery.TypeOrArray<LsApiSuccessCallback> = settings.success;
            let error = settings.error || (() => { });

            settings.success = [(result: ILsJsonResult | ILsStandardJsonResponse, status, xhr) => {
                if (!xhr.responseJSON) {
                    const requestId = LsApiUtilities.getRequestId(settings);
                    LsLogger.log(`Not a JSON response [requestId=${requestId}] [url=${settings.url}]`, "error");
                    LsApi.handleError(xhr, status, "", settings);
                    if (!Array.isArray(error)) {
                        error = [error];
                    }
                    for (const func of error) {
                        func(xhr, "error", "");
                    }
                } else if (LsJsonResultManager.isJsonResult(result) && result.sessionExpired) {
                    LsModals.openModalByTarget("#lsSessionExpiredNotification");
                } else {
                    if (!Array.isArray(success)) {
                        success = [success];
                    }
                    for (const func of success) {
                        func(result, status, xhr, settings);
                    }
                }
            }];
        }
    }

    protected static modifyErrorCallback(settings: ILsApiAjaxSettings) {
        let error = settings.error || (() => { });

        settings.error = [(xhr, status, err) => {
            LsApi.handleError(xhr, status, err, settings);
            if (!Array.isArray(error)) {
                error = [error];
            }
            for (const func of error) {
                func(xhr, status, err);
            }
        }];
    }
}

export default LsApi;